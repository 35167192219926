/*---------------------------------------------------------
------------ Custom styles for Get.gov --------------------
----------------------------------------------------------*/

$nav-search-min-width: 280px; 

@use 'uswds-core' as * ;

/*---------------------------------------------------------
UTILITIES
----------------------------------------------------------*/
.bg-primary-lightest {
  background-color: color('primary-lightest');
}

.bg-accent-cool-lighter {
  background-color: color('accent-cool-lighter');
}

.white-space--nowrap {
  white-space: nowrap;
}

@media (max-width: 1023px) {
  .hidden-mobile {
    display: none;
  }
}

@include at-media(desktop) {
  .hidden-desktop {
    display: none;
  }
}

/*---------------------------------------------------------
SITEWIDE
----------------------------------------------------------*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// sections
.usa-section {
  padding-top: units(4);
  padding-bottom: units(4); 
}

// Temporary styling for undetermined links
a[href="#"] {
  color: color('blue-80v') !important;
  background-color: color('gold-10v') !important; 
  padding: 0 units(0.5);

  &:after  {
    content: ' [link TBD]';
  }
}

a.usa-button:not(.usa-button--unstyled, .usa-button--outline) {
  color: color('white');
}

a.usa-button:not(.usa-button--unstyled, .usa-button--outline):visited,
a.usa-button:not(.usa-button--unstyled, .usa-button--outline):hover,
a.usa-button:not(.usa-button--unstyled, .usa-button--outline):focus,
a.usa-button:not(.usa-button--unstyled, .usa-button--outline):active {
  color: color('white');
}

.usa-alert--info--variant {
  background-color: color('primary-lightest');
  border: 1px solid color('accent-cool-light');
  border-radius: .25rem;
}

.usa-alert--info--variant .usa-alert__body {
  background-color: color('primary-lightest');
}

/*---------------------------------------------------------
TYPOGRAPHY
----------------------------------------------------------*/

// NOTE: font-size is defined in these explicitly because we also have related classes of the same thing.
// When defining a new heading level, make sure to include the font size for class support.
h1, 
.h1, 
.usa-prose > h1, 
.usa-prose > .h1 {
  font-size: size($theme-font-role-heading, $theme-h1-font-size);
  color: color('primary-darker');
}

h2, 
.h2, 
.usa-prose > h2, 
.usa-prose > .h2  {
  font-size: size($theme-font-role-heading, $theme-h2-font-size);
  color: color('primary-darker');
  line-height: line-height('heading', 3);
  font-weight: font-weight('semibold');
}

h3, 
.h3, 
.usa-prose > h3, 
.usa-prose > .h3  {
  font-size: size($theme-font-role-heading, $theme-h3-font-size);
  color: color('primary-dark');
  font-weight: font-weight('semibold');
}

h4, 
.h4, 
.usa-prose > h4, 
.usa-prose > .h4  {
  font-size: size($theme-font-role-heading, $theme-h4-font-size);
  color: color('primary-dark');
  font-weight: font-weight('semibold');
  .usa-prose > & {
    font-weight: font-weight('bold');
  }
}

h5, 
.h5, 
.usa-prose > h5, 
.usa-prose > .h5 {
  font-size: size($theme-font-role-heading, $theme-h5-font-size);
  color: color('primary-dark');
  font-weight: font-weight('semibold');
  .usa-prose > & {
    font-weight: font-weight('bold');
  }
}

.usa-section--dark h2 {
  color: color('white');
}

// Overrides default font size for sidenav sublist to match rest of sidenav
.usa-sidenav__sublist {
  font-size: 1.06rem;
}

// Overrides for the default usa-prose styles
.usa-prose {
  h2:not(.h3) {
    max-width: measure(1);
    margin-top: units(4);
    margin-bottom: 0;
  }

  > h3 {
    margin-top: units(3);
    margin-bottom: 0;
  }

  > h4 {
    margin-top: units(2.5);
  }

  h2 + h3 {
    margin-top: units(2);
  }

  p {
    margin-top: units(1);
    margin-bottom: units(2);
  }

  h1 + p {
    margin-top: units(3);
  }

  a {
    color: color('primary');

    &:hover, 
    &:focus {
      color: color('primary-darker');
    }
  }

  .usa-link--external {
    display: initial;
  }
}

/* markdown-it-anchor header link styling */
h1,
h2,
h3,
h4 {
  .header-anchor {
    text-decoration-line: none
  }

  &:hover {
    .header-anchor {
      text-decoration-line: underline
    }
  }
}

/*---------------------------------------------------------
HEADER
----------------------------------------------------------*/

.usa-nav__inner .usa-search button {
  background-color: color('primary');
}

.usa-nav__inner #search_form {
  @include at-media(desktop) {
    min-width: $nav-search-min-width;
  }
}

.usa-header + .usa-layout-docs {
  border-top: 1px solid color('base-lighter');
}


//Icon adjustments
.top-5px {
  top: 5px
}

// Override default .text-wrap to wrap pretty
// Ensures pseudo elements (eg. hyperlink icons) don't wrap by themselves
.text-wrap {
  text-wrap-style: pretty;
}

// Adds the following to all site links:
//  - underline on hover effect
//  - icon that appears inline after link content (using pseudoelement ::after)
// Excludes the following:
//  - all links that are buttons, headers, nav items, and tags
//  - "back" links
//  - external links (these have their own icon)

.usa-link--with-icon {
    :hover {
      text-decoration-line: underline !important;
    }
    
    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 1em;
      height: 1em;
      background-color: currentColor;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-size: contain;
      -webkit-mask-size: contain;
  
      //chevron icon
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M9 6l6 6-6 6'/%3E%3C/svg%3E");
      -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M9 6l6 6-6 6'/%3E%3C/svg%3E");
  
      //link icon
      // mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M4.5 12a4.5 4.5 0 0 1 4.5-4.5h2m4 0h2a4.5 4.5 0 0 1 0 9h-2m-4 0h-2a4.5 4.5 0 0 1-4.5-4.5'/%3E%3Cpath d='M9.5 12h7'/%3E%3C/svg%3E");
      // -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M4.5 12a4.5 4.5 0 0 1 4.5-4.5h2m4 0h2a4.5 4.5 0 0 1 0 9h-2m-4 0h-2a4.5 4.5 0 0 1-4.5-4.5'/%3E%3Cpath d='M9.5 12h7'/%3E%3C/svg%3E");
      
      //arrow icon
      // mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M4 12h16'/%3E%3Cpath d='M14 6l6 6-6 6'/%3E%3C/svg%3E");
      // -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M4 12h16'/%3E%3Cpath d='M14 6l6 6-6 6'/%3E%3C/svg%3E");    
    }
}

/*---------------------------------------------------------
FOOTER
----------------------------------------------------------*/
.usa-footer__secondary-section {
  background-color: color('primary-lightest');
  margin-top: units(6);

  a {
    color: color('primary');
  }

  @include at-media(tablet) {
    // workaround, as there is no uswds units(12)
    margin-top: units(6) * 2; 
  }

  .usa-footer__logo-img {
    max-width: units(9);
  }
}


.usa-footer__logo-img {
  max-width: units(7);
}

/*---------------------------------------------------------
HOME 
----------------------------------------------------------*/

// ## HERO ------------------------------------------------
.hero {
  //hide image on smallest screens, show on large mobile and up
  .hero-image {
    @include at-media(mobile-lg) {
      background-image: url('../_img/dotgov_hero.svg');
      background-position: 100% 30%;
      background-size: 40%;
      background-repeat: no-repeat;
    }

    @include at-media(tablet) {
      background-position: center right;
      background-size: auto;
    }
  }
}

.hero-heading {
  @include at-media(mobile-lg) {
    max-width: 60%;
  }
}

// ## DOMAIN SEARCH (domains_choosing page) ---------------------------------------
.usa-search--domain-choosing {
  flex-direction: column;
  // The default input styling has no styling on the right.
  // Lets correct that.
  input.usa-input {
    border-right-style: solid;
    border-right-width: 0.8px;
    border-right-color: rgb(86, 92, 101);
    max-width: 400px;
    height: 36px;
    font-size: inherit;
  }

  span {
    line-height: 2;
  }

  button.usa-button {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 36px;
  }

  @media (max-width: 500px) {
    input.usa-input {
      width: 90%;
    }

    button.usa-button {
      width: 100%;
    }
  }

}

// ## DOMAIN SEARCH ---------------------------------------
.usa-search--domain {
  flex-direction: column;
  gap: 16px;
  line-height: 1;
  font-size: 1rem;
  align-items: center;
  @include at-media(mobile-lg) {
    flex-direction: row;
  }
}

.usa-search--domain__form-group {
  width: 100%;
  @include at-media(mobile-lg) {
    width: auto;
  }
}

.usa-search--domain input {
  height: 2.125rem;
  @include at-media(mobile-lg) {
    width: 13.75rem;
  }
}

.usa-search--domain input:not([disabled]):focus {
  outline: 0rem;
  border: 0.25rem solid #2491ff;
}

// .gov
.usa-search--domain {
  span {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px solid #565c65;
    
    align-items: center;
    color: color('primary-darker');
    font-weight: bold;
    height: 2.125rem;
  }
}

.usa-search--domain-primary {
  @extend .usa-search--domain;
  span {
    background-color: color('primary-lightest');
    border: 1px solid #565c65;
  }
}

// Blue usa search bar for darker backgrounds
.usa-search--domain-secondary {
  @extend .usa-search--domain;
  span {
    background-color: #AFD5E6;
    border-left: none;
  }
}

.usa-search .usa-search--domain__submit {
  margin-right: 0;
  width: 100%;
  @include at-media(mobile-lg) {
    margin-left: units(2);
    width: auto;
  }
  padding-right: 1rem;
  padding-left: 1rem;
  height: 2.25rem;
  line-height: 0.5;
  border-bottom-left-radius: units(0.5);
  border-top-left-radius: units(0.5);
}

.domain-section__image {
  @include at-media(mobile-lg) {
    background-image: url('../_img/dotgov_availability.svg');
    background-position: 100% 30%;
    background-size: 40%;
    background-repeat: no-repeat;
  }

  @include at-media(tablet) {
    background-position: center right;
    background-size: auto;
    padding-top: 1%;
    padding-bottom: 4%;
  }
}

.domain-section__subtitle {
  @include at-media(mobile-lg) {
    max-width: 50%;
  }
  font-size: 1.06rem;
  line-height: 1.59rem;
}

.usa-search--domain_message-choosing {
  max-width: 400px;

  p:last-of-type {
    margin-bottom: 0.5rem;
  }

}

.usa-alert--success.usa-alert--slim::before {
  height: 3.5rem;
}

.usa-alert--error.usa-alert--slim::before {
  height: 3.5rem;
}

.usa-alert--info.usa-alert--slim::before {
  height: 3.5rem;
}

// ## WHOIS PAGE ------------------------------------------
.search-whois {
  max-width: 400px;
}

.whois-card {
  margin-bottom: 1.5rem;
  .usa-card__container {
    background-color: color('primary-lightest');
    .usa-card__header h3 {
      font-weight: 700;
    }

    h4 {
      font-size: 1.125rem;
      margin-block-end: 0;
    }
  }
}

#rdap-search-results {
  h2 {
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }
}

@media (min-width: 40em) {
  .usa-prose .usa-card {
    margin-bottom: 1rem;
  }
}

.usa-card--details-body {
  margin-left: units(1);
}

.usa-search--domain_message {
  @include at-media(mobile-lg) {
    max-width: 50%;
  }
  margin-top: units(2);
}

// ## HIGHLIGHTS ------------------------------------------
.highlight__benefits {
  .usa-graphic-list__row .usa-media-block {
    margin-bottom: units(2);
  }

  .usa-graphic-list__row .usa-media-block__img {
    margin-right: units(1.5);
  }
}

.highlight__benefits {
  @include at-media(tablet-lg) {
    background-image: url('../_img/dotgov_benefit_goverse.svg');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: contain;
  }
}

// ### LEARN ABOUT 
.highlight__learn {
  .usa-icon-list__icon {
    color: color('accent-cool-light');
    margin-right: units(1);
  }
  
  .highlight__learn__link,
  .highlight__learn__link:visited {
    color: color('primary');
  }

  .highlight__learn__link:focus,
  .highlight__learn__link:hover {
    color: color('primary-darker');
  }
}


// ## NEWS ----------------------------------------------- 
.blog-item {
  list-style-type: none;
  position:relative; 
}

.title-bullet {
    &:before {
      background-color: color('accent-cool-light');
      border: 2px solid color('primary');
      border-radius: 50%;
      content: "";
      display: block;
      position: absolute; 
      left: -1 * units(3);
      top: units(1);
      width: units(1.5);
      height: units(1.5);
    }
}


/*---------------------------------------------------------
LANDING PAGES 
----------------------------------------------------------*/
// ## PAGE HEADER -----------------------------------------
.page-header {
  background-image: url('../_img/dotgov_dotgrid.svg');
  background-repeat: no-repeat;
  background-position: 550% top;
  background-size: 90%;

  @include at-media(tablet) {
    background-position: right;
    background-size: 60%;
  }

  @include at-media(desktop) {
    background-size: 50%;
  }
}

.landing__index ul {
  list-style: none;
  margin-top: units(5);
  padding-left: 0;

  a {
    text-decoration: none;
  }

  a:visited {
    color: color('primary');
  }

  a:hover, 
  a:focus {
    color: color('primary-darker');
  }
}

/*---------------------------------------------------------
NEWS/BLOG  
----------------------------------------------------------*/
// ## PREVIEWS ------------------------------------------
.postpreview {
  border-top: 0;
}

.tags-list .usa-list--unstyled li {
  margin-bottom: units(1);
}

.usa-tag:only-of-type {
  margin-right: units(0.5);
}

/*---------------------------------------------------------
SEARCH
----------------------------------------------------------*/
.result__title {
  color: color('primary');
}

/*---------------------------------------------------------
CHECKLIST 
----------------------------------------------------------*/
.checklist+ul {
  list-style: none;

  li {
    margin-bottom: units(1);
    text-indent: (-1 * units(3));
  }

  li::before {
    background: url('../_img/icons/check_circle_outline.svg') no-repeat center left;
    background-size: 85%;
    content: "\200B"; // Marker is white space but still reads as list item
    padding-left: units(3);
  }
}

// This style gets overriden by other class overrides. We can fix this here.
.display-none {
  display: none !important;
}
